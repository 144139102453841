import {
    Link,
  } from "react-router-dom";

  import { isAdmin, isEditor, isLoggedIn } from './utils/auth.js';

export function Header() {
    return(
        <header className="header">
            <div className="logo">
                {(isAdmin() || isEditor()) && showDashboardLink()}
                <img src="../logo/lh (2).png" alt="" />
            </div>
            <div className="menu">
                {!isLoggedIn() && showLogin()}
                {isLoggedIn() && showUserLogin()}
            </div>
        </header>
    )
}

function showLogin(){
    return <Link className="login" to={`./Login`}><i className="fa-solid fa-arrow-right-to-bracket"></i></Link>
}

function showUserLogin(){
    return <Link className="login"><i className="fa-solid fa-arrow-right-to-bracket fa-flip-horizontal"></i></Link>
}

function showDashboardLink(){
    return <Link className="dashboardLogin" to={`./Dashboard`}><i className="fa-solid fa-gauge-high"></i></Link>
}