import { MainHeader } from "../MainHeader";
import { Comment } from "../Comment";
import { get, post } from "../utils/request";
import { useParams, useLocation } from "react-router-dom";
import {useState, useEffect} from 'react'

import {getUserId, isLoggedIn} from '../utils/auth'

import {
    Link,
  } from "react-router-dom";

import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

export function Lafz(){
    let { id } = useParams();
    const location = useLocation()
    const [expression, setExpression] = useState(location.state.expression ? { 
        expression: location.state.expression.expression,
        description: location.state.expression.description,
        ipa: location.state.expression.ipa,
        tags:location.state.expression.tags,
    } : {});
    const [comments, setComments] = useState([]);
    console.log(location);

    useEffect( () => {
        getExpression(id)
        getExpressionComments(id)
    }, [])

    async function getExpression(id){
        const response = await get(`api/expressions/${id}`)
        const e = (await response.json())
        setExpression(e)
        console.log(expression)
    }
    async function getExpressionComments(id){
        const response = await get(`api/expressions/${id}/comments`)
        const e = (await response.json())
        setComments(e)
    }

    async function like(){
        if(expression.liked){
            await post(`api/expressions/${id}/unlike`)
        }
        else {
            await post(`api/expressions/${id}/like`)
        }
        getExpression(id)
    }

    let tags = [];
    if (expression.tags){
        tags = expression.tags.trim() == "" ? [] : expression.tags.split(" ");
    }

    const [inputComment, setInputComment] = useState("");

    const commentChange = (event) => {
        setInputComment(event.target.value);
    };

    const callBack = async (event) => {
        event.preventDefault();
        await post("api/comments", { expression_id:id, comment:inputComment });
        setInputComment("");
        getExpressionComments(id);
    };
    
    const handleDelete = function(commentId) {
        return async () => {
            const userConfirmed = window.confirm("آیا مطمئن هستید که می‌خواهید این کامنت را حذف کنید؟");
            if (userConfirmed) {
                await post(`api/comments/${commentId}/delete`);
                getExpressionComments(id);
            }
        };
    }

    function showCommentInput(){
        return (
            <form className="comment-form" onSubmit={callBack}>
            <button type="submit" className="comment-submit">
                <i className="fa-solid fa-share"></i>
            </button>
            <input
                value={inputComment}
                onChange={commentChange}
                type="text"
                className="comment-input"
                placeholder="کامنت ..."
            />
            </form>
        )
    }

    function showCommentLogin(){
        return (
            <div className="comment-btn">
                <Link className="comment-btn-link" to={`/login`}>
                    <button><i class="fa-solid fa-arrow-right-to-bracket"></i> برای گذاشتن کامنت ابتدا لاگین کنید</button>
                </Link>
            </div>
        )
    }

    return(
        <main className="main">
            {expression != {} && <div className="main-div">
                <MainHeader />
                <div className="lafz-card">
                    <div className="lafz">
                        <div>
                        <i class="fa-solid fa-volume-high"></i>
                            <h1>{expression.expression}</h1>
                            <h4>/{expression.ipa}/</h4>
                        </div>
                    </div>
                    <div className="lafz-description">
                        <p>{expression.description}</p>
                    </div>
                    <div className="lafz-hashtag">
                    {
                        tags.map( tag => (
                            <div>
                                <a href={`/tags/${tag}`}>#{tag}</a>
                            </div>
                        ))
                    }
                    </div>
                    <div className="lafz-icon">
                        <div>
                            <p>{comments.length} <i id="comment" class="fa-regular fa-comment"></i></p>
                        </div>
                        <div onClick={() => like()}>
                            <p>{expression.likes_count} <i class={`${expression.liked==true ? 'fa-solid ' : 'fa-regular'} fa-heart`}></i></p>
                        </div>
                    </div>
                </div>
                <div className="comment-input-bar">
                    {isLoggedIn() ? showCommentInput() : showCommentLogin()}
                </div>
                <div className="comments-bar">
                    {comments.toReversed().map(comment =>  <Comment  showDelete={getUserId() == comment.userId} name={comment.name} comment={comment.comment} onDelete={handleDelete(comment.commentId)} />)}
                </div>
            </div>}
        </main>
    )
}