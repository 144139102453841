import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

import {
    Link,
  } from "react-router-dom";

  import axios from 'axios';
  import { useState } from 'react';

export function AddLafz() {
        // State to store form input values
        const [formData, setFormData] = useState({
            expression: '',
            description: '',
            ipa: '',
            tags: ''
        });

        // Handle form input changes
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData((prevData) => ({
            ...prevData,
            [name]: value
            }));
        };

        // Handle form submission
        const handleSubmit = (e) => {
            e.preventDefault(); // Prevent the default form submission

            // Make a POST request to save the data
            axios.post('https://lafzhub.com/api/expressions', formData)
            .then((response) => {
                console.log('Data saved successfully:', response.data);
                alert('Data saved successfully!');
            })
            .catch((error) => {
                console.error('Error saving data:', error);
                alert('Failed to save data!');
            });
        };
    return(
        <div>
            <section className="dashboard-panel">
                <aside className="dashboard-bar">
                    <div className="dashboard-header">
                        <h1><i className="fa-solid fa-gauge"></i> دشبورد</h1>
                    </div>
                    <div className="dashboard">
                        <Link className="link" to={`/Dashboard`}>
                        <div className="dashboard-tab">
                            <a href="#"><i className="fa-solid fa-list"></i> لفظ ها</a>
                        </div>
                        </Link>
                        <div className="dashboard-tab">
                            <a href="#"><i className="fa-solid fa-eye-low-vision"></i> دسترسی ها</a>
                        </div>
                    </div>
                </aside>
                <main className="main-panel">
                    <div className="main-header">
                        <h3><i className="fa-solid fa-file-circle-plus"></i> لفظ جدید</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className='lafz-input-bar'>
                        <div>
                            <input className='lafz-input' type='text' placeholder='لفظ به زبان فارسی'
                             name="expression"
                             value={formData.expression}
                             onChange={handleChange}
                             required></input>
                        </div>
                        <div>
                            <input id='input-ipa' className='lafz-input' type='text' placeholder='تلفظ به زبان انگلیسی'
                             name="ipa"
                             value={formData.ipa}
                             onChange={handleChange}
                             required></input>
                        </div>
                        <div>
                            <input className='lafz-input' placeholder='هشتگ'
                             name="tags"
                             value={formData.tags}
                             onChange={handleChange}
                             required></input>
                        </div>
                        <div>
                            <textarea id='input-description' className='lafz-input' type='text' placeholder='توضیحات لفظ به زبان فارسی'
                             name="description"
                             value={formData.description}
                             onChange={handleChange}
                             required></textarea>
                        </div>
                        
                        <div>
                            <button className='save-btn' type='submit'><i class="fa-solid fa-floppy-disk"></i> ذخیره لفظ</button>
                        </div>
                    </div>
                    </form>
                </main>
            </section>
        </div>
    )
}