import {
    Link,
  } from "react-router-dom";

export function LafzCard(props) {
    const {expression} = props;
    const tags = expression.tags.trim() === "" ? [] : expression.tags.split(" ");

    return(
        <div className="lafz-card">
            <Link className="link" to={`./e/${expression.id}`} state={{expression}}>
            <div className="lafz">
                <h1>{props.lafz}</h1>
            </div>
            <div className="lafz-description">
                <p>{props.description}</p>
            </div>
            </Link>
            <div className="lafz-hashtag">
            {
                tags.map( (tag, i) => (
                    <div key={i}>
                        <a href={`/tags/${tag}`}>#{tag}</a>
                    </div>
                ))
            }
            </div>
            <div className="lafz-icon">
                <div>
                    <Link className="link" to={`./Lafz`} state={{expression:props.expression}}>
                        <p>{props.comment} <i class="fa-regular fa-comment"></i></p>
                    </Link>
                </div>
                <div>
                    <p>{props.like} <i class="fa-regular fa-heart"></i></p>
                </div>
            </div>
        </div>
    )
}