export function Comment(props) {

    const { id, name, comment, onDelete, showDelete } = props;
    
    return(
        <div className="comments">
            <div className="comment-user">
                <i className="fa-solid fa-circle-user"></i>
                <p>کاربر {name}</p>
            </div>
            <div className="comment">
                <p>{comment}</p>
                {showDelete && <button className="comment-delete" onClick={() => onDelete(id)}>
                    <i className="fa-solid fa-trash-can"></i>
                </button>}
            </div>
        </div>
    )
}
