import { GoogleLogin } from '@react-oauth/google';
import { persistToken } from '../utils/auth';
import { useNavigate } from "react-router-dom";


import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

// localstorage, "data", {}

export function Auth() {
    const navigate = useNavigate();
    
    const onSuccess = (response) => {
        console.log('response: ', response)
        console.log('token: ', response.credential)
        // Send the response to the backend
        fetch('https://lafzhub.com/api/auth/google', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: response.credential }),
        })
        .then(res => res.json())
        .then(data => {
            // Handle the response from your backend
            console.log(data);
            persistToken(data.token);
            window.location = window.location.origin;

        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const onFailure = (response) => {
        console.log('google login failed: ', response)
    };

    return(
        <div className="login-btn-bar">
            <div className='login-btn'>
                <h1>
                  <span id='login-1'>ورود</span> <span id='login-2'>با</span> <span id='login-3'>گو</span><span id='login-4'>گل</span>
                </h1>
            <GoogleLogin
                clientId="21287849907-4h80japnuresns7v563covpfb4fmftbg.apps.googleusercontent.com"
                buttonText="Sign in with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
            />
            </div>
        </div>
    )
}