import { Header } from "../Header"
import { LafzCard } from "../LafzCard"

import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

import {useState, useEffect} from 'react'
import {get} from '../utils/request'

export function Home(){
    const [expressions, setExpression] = useState([])

    useEffect( () => {
        getExpressions()
    }, [])

    async function getExpressions(){
        const response = await get('api/expressions')
        const expressions = (await response.json()).data
        setExpression(expressions)
        console.log(expressions)
    }

    return (
        <main className="main">
            <div className="main-div">
            <Header />

            {
            expressions.map( expression => <LafzCard
                expression = {expression}
                lafz = {expression.expression}
                ipa = {expression.expression}
                hashtags = {expression.tags}
                description = {expression.description}
                like = "0"
                comment = "0"
                key={ expression.id}
            />)
            }
            </div>
        </main>
    )
}
