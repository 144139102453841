import {
    Link,
  } from "react-router-dom";

export function MainHeader() {
    return(
        <header id="lafz-header" className="header">
            <div className="logo">
                <Link to={`/`}>
                    <img src="../logo/lh (2).png" alt="" />
                </Link>
            </div>
                <Link className="back-link" to={`/`}>
                    <i className="fa-solid fa-circle-arrow-left"></i>
                </Link>
        </header>
    )
}